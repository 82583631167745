/****
 General
***/
html body {
  height: 100%;
  -webkit-font-smoothing: antialiased;
}
body {
  background: url("/assets/images/oil2.jpg") no-repeat center center fixed;
  background-size: cover;
}
@media screen and (max-width: 768px) {
  body {
    background-size: cover;
  }
}
div {
  height: 100%;
	line-height: 1.8;
	font-family: "Droid Serif";
}
.absolute-bottom {
  position: absolute;
  bottom: 0;
}
.clearfix:after {
  content:" ";
  display:table;
  clear:both;
}
.link {
  text-transform: uppercase;
  font-family: "helvetica", sans-serif;
  letter-spacing: 2px;
  font-weight: bold;
  font-size: 12px;
  color: #646464;
  cursor: pointer;
}
.heading {
  text-transform: uppercase;
  font-family: "helvetica", sans-serif;
  letter-spacing: 2px;
  font-weight: normal;
  font-size: 12px;
  color: #646464;
  cursor: pointer;
}
.page-title {
  font-family: "Domine", serif;
  margin-top: 150px;
  margin-bottom: 100px;
  font-size: 3.5rem;
  text-align: center;
  color: white;
}

/****
 Navbar
***/
.main-nav {
  position: fixed;
  box-shadow: 0 0 20px rgba(0,0,0,.2);
  height: 80px;
  margin-top: 0;
  width: 100%;
  background-color: white;
  opacity: 1.0;
  text-transform: uppercase;
  font-family: "Domine", serif;
  color: #333;
  letter-spacing: 3px;
  font-weight: bold;
  font-size: 12px;
  z-index: 2;
  display: flex;
  justify-content: space-around;
}
.main-nav a {
  position: relative;
  text-decoration: none;
  color: black;
  margin-left: 1rem;
  margin-right: 1rem;
  opacity: 1.0;
  z-index: 2;
}
.main-nav a:hover {
  opacity: 0.6;
}
.animate-show.ng-hide-add, .animate-show.ng-hide-remove {
  transition: 0.1s linear all;
}
.animate-show.ng-hide {
  margin-top: -40px;
}
.nav-menu {
  display: flex;
  align-items: center;
  font-size: 0.8rem;
  font-weight: 100;
}
.nav-take-action {
  display: flex;
  align-items: center;
  font-size: 0.8rem;
  font-weight: 100;
}
.nav-menu ul {
  display: flex;
  list-style: none;
}
.nav-menu ul li:first-child {
  border-right: 1px solid #CCC;
}
.menu-title {
  display: flex;
  align-items: center;
  font-size: 1.6rem;
  font-weight: 200;
}
.menu-title .smoke-and-fumes {
  margin-left: 8px;
  margin-right: 8px;
  border-bottom: none;
  transition: 0.1s linear all;
}
.menu-title .smoke-and-fumes:hover {
  opacity: 0.6;
}
.active-title {
  border-bottom: 1px solid #CCCCCC !important;
}

@media screen and (max-width: 768px) {
  .main-nav {
    display: none;
  }
}
/****
 Mobile Nav Button
***/
.mobile-nav-button {
  position: absolute;
  display: none;
  left: 20px;
  top: 20px;
  height: 30px;
  background-color: transparent;
  z-index: 2;
}
.mobile-nav-button .fa-bars {
  color: white;
  font-size: 2.0rem;
}
.mobile-nav-open .fa-bars {
  color: black;
}
@media screen and (max-width: 768px) {
  .mobile-nav-button {
    display: flex;
  }
}
/****
 Mobile Nav Menu
***/
.mobile-nav {
  background: white;
  opacity: 0.6;
  height: 100%;
  width: 100vw;
  font-size: 2rem;
  font-weight: 100;
  text-transform: uppercase;
  font-family: "Domine", serif;
  letter-spacing: 3px;
  font-weight: bold;
  text-align: center;
  overflow-y: scroll;
}
.mobile-nav-menu ul {
  list-style: none;
  width: 70%;
  margin-left: auto;
  margin-right: auto;
  margin-top: 0;
  padding-top: 1rem;
}
@media screen and (max-width: 640px) {
  .mobile-nav-menu ul {
    margin-left: 1em;
  }
}
.mobile-nav-menu li {
  padding-top: 1rem;
  padding-bottom: 1rem;
}
.mobile-nav-menu a {
  text-decoration: none;
  color: #333;
}
.mobile-nav.ng-show-add {

}
/****
 Video
***/
.video {
  padding-top: 80px;
  padding-bottom: 40px;
}
.video iframe {
  display: block;
  margin: 0 auto;
  max-width: 720px;
}
.button {
  display: block;
  margin: auto;
  margin-top: 50px;
  padding-top: 10px;
  padding-bottom: 10px;
  width: 140px;
  font-family: "Domine", serif;
  font-size: 11px;
  text-align: center;
  text-transform: uppercase;
  text-decoration: none;
  letter-spacing: 2.5px;
  font-weight: 500;
  color: #000;
  background-color: #fff;
  border: none;
  border-radius: 45px;
  box-shadow: 0px 8px 15px rgba(0, 0, 0, 0.1);
  transition: all 0.3s ease 0s;
  cursor: pointer;
  outline: none;
  }
.button:hover {
  background-color: #333333;
  box-shadow: 0px 15px 20px rgba(92, 65, 45, 0.4);
  color: #fff;
  transform: translateY(-7px);
}
@media screen and (max-width: 640px) {
  .video iframe {
    display: block;
    margin: 0 auto;
    max-width: 320px;
    max-height: 220px;
  }
}

/****
 Footer
***/
footer {
  position: relative;
  bottom: 0;
  background-color: white;
  height: 50px;
  padding-bottom: 40px;
  transition: 0.1s ease-in-out;
  box-shadow: 0 0 20px rgba(0,0,0,.2);
  border-radius: 3px 3px 0 0;
}
.footer-right {
  display: flex;
  margin-top: 20px;
}
.footer-left {
  margin-top: 20px;
  width: 220px;
}
.footer-center {
  margin-top: 20px;
  display: flex;
  align-items: center;
}
.footer-right img {
  padding-right: 20px;
  height: 32px;
}
.contact {
  display: flex;
  justify-content: space-around;
  margin-bottom: 20px;
}
.credits {
  color: black;
  text-align: center;
  font-size: .6rem;
}
.credits a {
  text-decoration: none;
  color: #545C75;
}
.credits {
  margin-bottom: 0px;
}
.logo {
  width: 100%;
}
.footer-button {
  display: inline-block;
  background-color: rgba(0, 0, 0, 0.7);
  color: white;
  width: 180px;
  margin-top: 0;
  padding: 5px;
  margin-right: 30px;
}

@media screen and (max-width: 768px) {
  .footer-left,
  .footer-right {
		display: none !important;
	}
}
@media screen and (max-width: 640px) {
  .credits {
    display: none !important;
  }
  .contact {
    display: block;
  }
  .footer-center {
    margin: 0 0 0 20px;
  }
  .footer-button {
    padding: 20px;
    max-height: 20px;
  }
  .footer-button:last-child {
    padding-top: 10px;
    padding-bottom: 30px;
  }
}
